var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "onpoint-input-currency" }, [
    _c("p", { staticClass: "onpoint-input-currency-label" }, [
      _vm._v("\n    " + _vm._s(_vm.label) + "\n    "),
      _vm.required
        ? _c("i", { staticClass: "onpoint-input-currency-required" }, [
            _vm._v(" *"),
          ])
        : _vm._e(),
    ]),
    _c("div", { staticClass: "onpoint-input-currency-common" }, [
      _c("input", {
        staticClass: "onpoint-input-currency-common-insideInput",
        attrs: { type: "text", placeholder: "R$", disabled: _vm.disabled },
        domProps: { value: _vm.valueFormatted },
        on: {
          input: _vm.onInputMask,
          change: function ($event) {
            return _vm.$emit("change")
          },
        },
      }),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }