<template>
  <div class="onpoint-input-currency">
    <p class="onpoint-input-currency-label">
      {{ label }}
      <i class="onpoint-input-currency-required" v-if="required"> *</i>
    </p>
    <div class="onpoint-input-currency-common">
      <input
        type="text"
        class="onpoint-input-currency-common-insideInput"
        :placeholder="'R$'"
        :value="valueFormatted"
        @input="onInputMask"
        @change="$emit('change')"
        :disabled="disabled"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    label: String,
    required: Boolean,
    disabled: Boolean,
    value: {
      type: [String, Number],
    },
  },
  data() {
    return {
      date: null,
      valueFormatted: null,
    };
  },
  created() {
    if (this.value || this.value == 0) {
      this.formatValue(this.value);
    }
  },
  watch: {
    value(value) {
      if (value || value == 0) {
        this.formatValue(value);
      }
      if (!value && value != 0) {
        this.valueFormatted = null;
      }
    },
  },
  methods: {
    formatValue(value) {
      if (value == null || value === "") {
        this.valueFormatted = "";
        return;
      }

      let numericValue = parseFloat(value);
      if (isNaN(numericValue)) {
        this.valueFormatted = "";
        return;
      }

      this.valueFormatted = numericValue.toLocaleString("pt-BR", {
        style: "currency",
        currency: "BRL",
      });
    },
    
    onInputMask(event) {
      let valueWithoutMask = event.target.value.replace(/\D/g, "");
      let numericValue = parseFloat(valueWithoutMask) / 100;

      this.$emit("input", numericValue);
    },
  },
};
</script>

<style lang="scss" scoped>
.onpoint-input-currency {
  display: flex;
  flex-direction: column;

  :deep(.p-inputtext) {
    font-family: "Montserrat", Helvetica, Arial, sans-serif !important;
    padding: 10.5px;
  }

  &-label {
    font-family: "Montserrat", Helvetica, Arial, sans-serif !important;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: rgba(55, 65, 81, 1);
    margin-bottom: 6px;
  }

  &-required {
    color: rgba(180, 23, 58, 1);
  }

  &-common {
    &-insideInput {
      font-family: "Montserrat", Helvetica, Arial, sans-serif !important;
      font-size: 12px;
      height: 38px;
      padding: 10.5px;
      color: #3f3f46;
      width: 100%;
      border: solid 1px rgba(229, 231, 235, 1);
      border-radius: 12px;
      box-shadow: 0 0 #0000, 0 0 #0000, 0 1px 2px 0 rgba(0, 0, 0, 0.07);
    }
  }
}
</style>
