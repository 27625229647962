<template>
  <section>
    <Card class="card">
      <template #title>
        <div class="title">
          <p>Dados Financeiros</p>
        </div>
      </template>
      <template #subtitle>
        <div class="subtitle">
          <p>Informe a forma de pagamento da proposta</p>
        </div>
      </template>
      <template #content>
        <div v-if="proposta">
          <div class="flex gap-5">
            <OnpointInputCurrency
              label="Valor do prêmio"
              v-model="proposta.InsurancePremium"
              class="mb-5 w-1/4"
              :disabled="true"
            />
            <OnpointInput
              label="Taxa aplicada (%)"
              class="mb-5 w-1/4"
              :disabled="true"
              v-model="proposta.RiskRate"
            />
            <OnpointInputCurrency
              label="Valor da comissão"
              class="mb-5 w-1/4"
              :disabled="true"
              v-model="proposta.ComissionValue"
            />
            <OnpointInput
              label="% Comissão"
              class="mb-5 w-1/4"
              :disabled="true"
              v-model="proposta.ComissionPercentage"
            />
          </div>
          <div class="mb-5">
            <Cocorretagem />
          </div>
          <div v-if="propostaFinancialData.InstallmentResult">
            <p class="mb-5 title">Forma de pagamento</p>
            <div class="flex gap-5">
              <div class="mb-5 w-1/4">
                <OnpointSelect
                  label="Número de parcelamento"
                  placeholder="Selecione"
                  :required="true"
                  v-model="dataInstallment"
                  :items="
                    propostaFinancialData.InstallmentResult.InstallmentOptions
                  "
                  optionLabel="Description"
                />
              </div>
              <div class="mb-5 w-1/4">
                <OnpointInput
                  label="Vencimento da 1ª parcela"
                  :required="true"
                  :disabled="true"
                  :calendar="true"
                />
              </div>
              <div class="mb-5 w-1/4">
                <OnpointSelect
                  label="Dia de vencimento das parcelas"
                  :required="true"
                  v-model="dayToPayInstallment"
                  :items="
                    propostaFinancialData.InstallmentResult
                      .PossibleGracePeriodsInDays
                  "
                  placeholder="Selecione"
                />
              </div>
              <div class="mb-5 w-1/4">
                <OnpointInputCurrency
                  v-if="dataInstallment"
                  label="Fracionamento na parcela (R$)"
                  :value="dataInstallment.InterestValuePerInstallment"
                  :disabled="true"
                />
                <OnpointInput
                  v-else
                  label="Fracionamento na parcela (R$)"
                  :disabled="true"
                />
              </div>
            </div>
          </div>
        </div>
      </template>
      <template #footer>
        <div class="flex justify-end">
          <Button
            label="Cancelar"
            class="p-button-rounded cancel-button"
            @click="$router.push({ name: 'propostas' })"
          />
          <Button
            label="Salvar proposta"
            class="p-button-rounded default-button"
            @click="
              enviarEventoAppInsights('salvar-proposta'), salvarProposta()
            "
          />
          <Button
            label="Imprimir minuta"
            class="p-button-rounded default-button"
            @click="imprimirMinuta()"
          />
          <Button
            label="Emitir minuta"
            class="p-button-rounded"
            @click="emitirApolice()"
            :disabled="!canEmit"
          />
        </div>
      </template>
    </Card>
    <Dialog
      class="termos-aceite-modal"
      title=""
      :visible.sync="assinarNovoTermoDeAceiteProposta"
      :containerStyle="{ width: '70vw' }"
    >
      <div class="vw-row">
        <div class="vx-col w-full">
          <h4>Termo e declaração</h4>
          <small
            ><b
              >Leia os termos para a solicitação de emissão da apólice</b
            ></small
          >
        </div>
      </div>
      <div class="vw-row mt-3">
        <div class="vx-col w-full" style="background-color: '#f2f2f2'">
          <span class="text-justify" v-html="TermoAceite"></span>
        </div>
      </div>
      <div class="vw-row mt-3">
        <div class="vx-col w-full">
          <input
            type="checkbox"
            class="mt-8 mb-4 mx-0 alingCheckbox"
            v-model="aceitouTermosDeAceiteProposta"
          />
          Declaro que li e estou ciente das condições acima.
        </div>
      </div>
      <div class="vw-row mt-3">
        <div class="vx-col w-full text-right">
          <Button @click="cancelarEmissao()" class="button-default mr-5">
            Cancelar
          </Button>
          <Button
            :disabled="!aceitouTermosDeAceiteProposta"
            @click="validaDataVigencia()"
            class="button-primary"
          >
            Emitir
          </Button>
        </div>
      </div>
    </Dialog>
    <Dialog
      class="validacao-data-vigente-modal"
      title=""
      :visible.sync="editaDataVigente"
    >
      <div class="vw-row">
        <div class="vx-col w-full">
          <h4>Aviso</h4>
          <small
            ><b
              >A data de vigência da proposta não pode ser retroativa.</b
            ></small
          >
        </div>
      </div>
      <div class="vw-row mt-3">
        <div class="vx-col w-full" style="background-color: '#f2f2f2'">
          <span> {{ mensagemRetornoData }}.</span>
        </div>
      </div>
      <div class="vw-row mt-3">
        <div class="vx-col w-full">
          <span>Deseja alterar?</span>
        </div>
      </div>
      <div class="vw-row mt-3">
        <div class="vx-col w-full text-right">
          <vs-button @click="cancelarEdicaoDatas()" class="button-default mr-5">
            Cancelar
          </vs-button>
          <vs-button @click="confirmarEmissao(true)" class="button-primary">
            Confirma
          </vs-button>
        </div>
      </div>
    </Dialog>
    <Dialog
      class="modal-explicativo-emissao-mapfre"
      title=""
      :visible.sync="explicativoEmissaoMapfre"
    >
      <div class="vw-row">
        <div
          class="vx-col w-full text-center modal-explicativo-emissao-mapfre-content"
        >
          <h4>Atenção</h4>
          <p>
            Sua solicitação está sendo processada. Caso a Apólice/Boleto(s)
            ainda não esteja(m) disponível(eis) para download na próxima tela,
            vá para a área de consulta de propostas ou apólices e verifique se
            está(ão) disponível(eis). Você também receberá no seu e-mail a
            confirmação da emissão da apólice.
          </p>
        </div>
      </div>

      <div class="vw-row mt-3">
        <div class="vx-col w-full text-center">
          <vs-button @click="prosseguirEmissaoMapfre()" class="button-primary">
            Prosseguir
          </vs-button>
        </div>
      </div>
    </Dialog>
  </section>
</template>

<script>
import Card from "primevue/card";
import Button from "primevue/button";
import OnpointInput from "@/components/onpoint-input";
import OnpointInputCurrency from "@/components/onpoint-input-currency";
import OnpointSelect from "@/components/onpoint-select/index.vue";
import Cocorretagem from "/src/views/propostas-new/criacao/dadosFinanceiros/components/Cocorretagem.vue";
import Dialog from "primevue/dialog";

import { mapGetters, mapMutations, mapActions } from "vuex";
import axiosInstance from "@/axios";
import PerfilHelpers from "@/helpers/PerfilHelpers";

export default {
  props: {
    formData: Object,
  },
  components: {
    Card,
    Button,
    OnpointInput,
    OnpointSelect,
    OnpointInputCurrency,
    Cocorretagem,
    Dialog,
  },
  data() {
    return {
      previousProposal: null,
      perfilHelpers: PerfilHelpers,
      dataInstallment: {
        InterestValuePerInstallment: 0,
      },
      dayToPayInstallment: null,
      assinarNovoTermoDeAceiteProposta: false,
      TermoAceite: "",
      aceitouTermosDeAceiteProposta: false,
      explicativoEmissaoMapfre: false,
      mensagemRetornoData: null,
      editaDataVigente: null,
    };
  },
  watch: {
    dataInstallment(val) {
      this.updateProposta({
        ...this.proposta,
        Installments: val.Installments,
      });
    },
  },
  computed: {
    ...mapGetters("proposta-module", [
      "proposta",
      "propostaFinancialData",
      "edicaoDeProposta",
    ]),
    canEmit() {
      return this.dataInstallment &&
        this.dataInstallment.Installments &&
        this.dayToPayInstallment
        ? true
        : false;
    },
  },
  methods: {
    nextPage() {
      this.$emit("nextPage", { formData: {}, pageIndex: 4 });
    },
    prevPage() {
      this.$emit("prevPage", { pageIndex: 4 });
    },
    ...mapMutations("proposta-module", ["updateProposta"]),
    ...mapActions("proposta-module", [
      "calcularDadosFinanceirosProposta",
      "calcularDadosFinanceirosPropostaSeguradora",
      "loadPropostaByUniqueId",
      "getValidateRetroactivityDays",
    ]),
    async getProposalByUniqueId() {
      return await this.$onpoint.loading(async () => {
        return await this.loadPropostaByUniqueId(
          this.$route.params.propostaUniqueId
        )
          .then((data) => {
            this.buscarTermoAceite();
          })
          .catch((error) => {
            this.$onpoint.errorModal(error.response.Message);
          });
      });
    },
    async buscarTermoAceite() {
      return await axiosInstance
        .get("api/CompanyAcceptanceTerm/GetLastCompanyAcceptanceTerm")
        .then((response) => {
          this.TermoAceite = response.data.Response.AcceptanceTerm;
        });
    },
    async GetPreviousProposalByUniqueId() {
      return await axiosInstance
        .get(
          `/api/proposal/GetPreviousProposalByUniqueId?ProposalUniqueid=${this.$route.params.propostaUniqueId}`
        )
        .then((response) => {
          this.previousProposal = response.data.Response;
        });
    },
    async carregarDadosFinanceirosProposta(proposta) {
      return await this.$onpoint.loading(async () => {
        if (this.perfilHelpers.isInsurance()) {
          return await this.calcularDadosFinanceirosPropostaSeguradora().catch(
            (ex) => {
              this.$onpoint.errorModal(ex.response.data.Errors);
            }
          );
        } else {
          return await this.calcularDadosFinanceirosProposta(proposta).catch(
            (ex) => {
              this.$onpoint.errorModal(ex.response.data.Errors);
            }
          );
        }
      });
    },
    enviarEventoAppInsights(action) {
      this.$appInsights.trackEvent({
        name: `${action}-proposta`,
        properties: {
          place: "proposta-passo-4",
          action: "button-click",
          slug: `${action}-proposta-passo-4`,
        },
      });
    },
    salvarProposta() {
      let payload = JSON.parse(JSON.stringify(this.proposta));
      if (!payload.ProposalId) {
        payload.ProposalId = payload.Id;
      }
      if (!payload.Coverages) {
        payload.Coverages = JSON.parse(
          JSON.stringify(
            this.$store.state["proposta-module"].propostaFinancialData.Coverages
          )
        );
        let array = [];
        payload.Coverages.map((x) => {
          array.push({
            Value: x.CoveragePremium,
            CoverageId: x.CoverageId,
            CoveragePremium: x.CoveragePremium,
          });
        });
        payload.Coverages = array;
      }
      if (!payload.ContractualTerms) {
        payload.ContractualTerms = payload.ProposalContractualTerms;
        payload.ContractualTerms.map((x) => {
          x.ContractualTermId = x.Id;
        });
      }
      payload.InsurancePremiumPrincipalModality =
        this.$store.state[
          "proposta-module"
        ].propostaFinancialData.PrincipalModality;
      payload.InsurancePremium =
        this.$store.state[
          "proposta-module"
        ].propostaFinancialData.InsurancePremium;
      payload.InsurancePremiumComplementaryModality =
        this.$store.state[
          "proposta-module"
        ].propostaFinancialData.ComplementryModality;
      payload.ComissionPercentage =
        this.$store.state[
          "proposta-module"
        ].propostaFinancialData.ComissionPercentage;
      payload.ComissionValue =
        this.$store.state[
          "proposta-module"
        ].propostaFinancialData.ComissionValue;
      payload.RiskRate =
        this.$store.state["proposta-module"].propostaFinancialData.Tax;

      let result = this.validaDadosProposta(payload);
      if (result) {
        this.$onpoint.errorModal("Dados inválidos: " + result);
        return;
      }

      this.$onpoint.loadingModal("Estamos salvando a proposta", () => {
        return axiosInstance
          .post("api/proposal/UpdateProposal", payload)
          .then((response) => {
            this.$vs.notify({
              title: "Sucesso",
              text: "Proposta salva com sucesso!",
              color: "success",
            });
            setTimeout(() => {
              this.$router.push({
                name: "timeline-new",
                params: { propostaUniqueId: this.proposta.UniqueId },
              });
            }, 500);
          })
          .catch((errors) => {
            this.$onpoint.errorModal(errors.response.data.Errors);
          });
      });
    },
    validaDadosProposta(dados) {
      let result = false;
      if (dados.ProposalId == null) {
        result = "Id da proposta";
      }
      if (dados.GracePeriod == null) {
        result = "Período de carência";
      }
      if (dados.InsurancePremium == null) {
        result = "Prêmio da modalidade principal";
      }
      if (dados.ComplementaryModality.Id != 0) {
        if (dados.InsurancePremiumComplementaryModality == null) {
          result = "Prêmio da modalidade complementar";
        }
      }
      if (dados.RiskRate == null || undefined) {
        result = "Taxa de risco";
      }
      if (dados.ComissionValue == null || undefined) {
        result = "Comissão";
      }
      if (dados.BlockChangesInProposal == null || undefined) {
        result = "Bloquear alterações de valores da Proposta";
      }
      if (dados.InsurancePremiumSetToZero == null || undefined) {
        result = "Parâmetro - InsurancePremiumSetToZero";
      }
      if (dados.EnableToBigRisk == null || undefined) {
        result = "Grande risco";
      }
      if (dados.EnableToMonitoring == null || undefined) {
        result = "Monitoramento";
      }
      if (dados.InsurancePremiumSetManually == null || undefined) {
        result = "Parâmetro - InsurancePremiumSetManually";
      }
      if (dados.EnableToSteppin == null || undefined) {
        result = "Retoma de objeto (Step-in)";
      }
      if (dados.ProposalContractualTerms.length <= 0) {
        result = "Objetos da proposta";
      }
      return result;
    },
    async imprimirMinuta() {
      let payload = JSON.parse(JSON.stringify(this.proposta));
      if (!payload.ProposalId) {
        payload.ProposalId = payload.Id;
      }
      if (!payload.Coverages) {
        payload.Coverages = JSON.parse(
          JSON.stringify(
            this.$store.state["proposta-module"].propostaFinancialData.Coverages
          )
        );
        let array = [];
        payload.Coverages.map((x) => {
          array.push({
            Value: x.CoveragePremium,
            CoverageId: x.CoverageId,
            CoveragePremium: x.CoveragePremium,
          });
        });
        payload.Coverages = array;
      }
      if (!payload.ContractualTerms) {
        payload.ContractualTerms = payload.ProposalContractualTerms;
        payload.ContractualTerms.map((x) => {
          x.ContractualTermId = x.Id;
        });
      }
      payload.InsurancePremiumPrincipalModality =
        this.$store.state[
          "proposta-module"
        ].propostaFinancialData.PrincipalModality;
      payload.InsurancePremium =
        this.$store.state[
          "proposta-module"
        ].propostaFinancialData.InsurancePremium;
      payload.InsurancePremiumComplementaryModality =
        this.$store.state[
          "proposta-module"
        ].propostaFinancialData.ComplementryModality;
      payload.ComissionPercentage =
        this.$store.state[
          "proposta-module"
        ].propostaFinancialData.ComissionPercentage;
      payload.ComissionValue =
        this.$store.state[
          "proposta-module"
        ].propostaFinancialData.ComissionValue;
      payload.RiskRate =
        this.$store.state["proposta-module"].propostaFinancialData.Tax;

      let result = this.validaDadosProposta(payload);
      if (result) {
        this.$onpoint.errorModal("Dados inválidos: " + result);
        return;
      }
      this.enviarEventoAppInsights("imprimir-minuta");
      await this.$onpoint.loading(async () => {
        return await axiosInstance
          .post("api/Proposal/UpdateProposal", payload)
          .then(async () => {
            return await axiosInstance
              .get(
                `api/Proposal/GenerateContractDraft?ProposalUniqueId=${this.proposta.UniqueId}&IsSaved=false`
              )
              .then((response) => {
                window.open(response.data.Response.UrlToDownload, "_self");
              })
              .catch((errors) =>
                this.$onpoint.errorModal(errors.response.data.Errors)
              );
          })
          .catch((errors) =>
            this.$onpoint.errorModal(errors.response.data.Errors)
          );
      });
    },
    emitirApolice() {
      this.enviarEventoAppInsights("abrir-emitir-apolice-modal");
      this.assinarNovoTermoDeAceiteProposta = true;
    },
    async validaDataVigencia() {
      this.assinarNovoTermoDeAceiteProposta = false;
      this.aceitouTermosDeAceiteProposta = false;

      this.enviarEventoAppInsights("emitir-apolice");
      await this.$onpoint.loadingModal(
        "Estamos validando a apólice",
        async () => {
          return await this.getValidateRetroactivityDays(this.proposta.UniqueId)
            .then((res) => {
              if (res.HasChangedDates) {
                this.mensagemRetornoData = res.Message;
                this.editaDataVigente = true;
              } else {
                setTimeout(() => {
                  this.confirmarEmissao(false);
                }, 500);
              }
            })
            .catch((errors) => {
              this.$onpoint.errorModal(errors.response.data.Errors);
            });
        }
      );
    },
    cancelarEmissao() {
      this.enviarEventoAppInsights("fechar-emitir-apolice-modal");
      this.assinarNovoTermoDeAceiteProposta = false;
      this.aceitouTermosDeAceiteProposta = false;
    },
    confirmarEmissao(dateHasChanged) {
      this.assinarNovoTermoDeAceiteProposta = false;
      this.aceitouTermosDeAceiteProposta = false;
      this.editaDataVigente = false;

      if (!this.checkCoBrokers()) {
        this.$onpoint.errorModal(
          "A cocorretagem tem que somar 100% do valor da comissão"
        );
        return;
      }

      this.$onpoint.loadingModal("Estamos emitindo a apólice", () => {
        const endpoint = dateHasChanged
          ? "api/Proposal/UpdateRetroactivityDaysAndCreatePolicy"
          : "api/Proposal/CreatePolicy";
        return axiosInstance
          .post(endpoint, this.proposta)
          .then((response) => {
            this.$vs.notify({
              title: "Sucesso",
              text: "Solicitação de emissão de apólice realizada com sucesso!",
              color: "success",
            });

            if (!this.Mapfre) {
              setTimeout(() => {
                this.$router.push({
                  name: "timeline-new",
                  params: { propostaUniqueId: this.proposta.UniqueId },
                });
              }, 500);
            } else {
              this.exibirPopupExplicativoMapfre();
            }
          })
          .catch((errors) => {
            this.$onpoint.errorModal(errors.response.data.Errors);
          });
      });
    },
    checkCoBrokers() {
      const totalCoBrokers =
        this.proposta.CoBrokers &&
        this.proposta.CoBrokers.reduce(
          (total, coBroker) => parseFloat(total) + parseFloat(coBroker.Rate),
          0
        );

      return totalCoBrokers === 100 || this.proposta.CoBrokers.length === 0
        ? true
        : false;
    },
    async init() {
      await this.getProposalByUniqueId();
      await this.GetPreviousProposalByUniqueId();
      await this.carregarDadosFinanceirosProposta(this.previousProposal);
    },
    cancelarEdicaoDatas() {
      this.assinarNovoTermoDeAceiteProposta = false;
      this.aceitouTermosDeAceiteProposta = false;
      this.editaDataVigente = false;
    },
    exibirPopupExplicativoMapfre() {
      this.explicativoEmissaoMapfre = true;
    },
  },
  mounted() {
    this.init();
  },
};
</script>

<style lang="scss" scoped>
.card {
  padding: 24px;
  border-radius: 16px;
  width: 100%;
}
.title {
  font-family: "Montserrat", Helvetica, Arial, sans-serif;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
}
.subtitle {
  font-family: "Montserrat", Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
}
.card-button-co-corretagem {
  width: 100%;
  background-color: #f9fafb;
  box-shadow: none;
  border: none;
  border-radius: 12px;
  :deep(.p-card-content) {
    padding: 0px;
  }
  .content {
    display: flex;
    justify-content: center;
  }
}
.default-button {
  background: white;
  color: black;
  border: solid 1px rgba(209, 213, 219, 1);
  margin-right: 5px;
}
.cancel-button {
  background: white;
  color: black;
  border: none;
  margin-right: 5px;
}
.alingCheckbox {
  position: relative;
  top: 2px;
}
.modal-explicativo-emissao-mapfre{
  z-index: 52900 !important;

  &-content{
    padding: 0 30px;

    h4 {
      font-size: 24px;
      margin-bottom: 30px;
    }

    p{
      font-size: 18px;
      margin-bottom: 30px;
    }
  }
}
</style>
