<template>
  <div>
    <div>
      <div v-if="existeCocorretor">
        <div
          v-for="(cocorretor, index) in cocorretores"
          :key="index"
          class="flex"
        >
          <div class="card-component mb-5 p-5">
            <p class="title mb-3">
              {{ index == 0 ? "Corretor lider" : "Corretagem" }}
            </p>
            <div class="flex gap-5">
              <OnpointInput
                required
                label="Razão social ou CNPJ"
                class="w-7/12"
                v-model="cocorretor.RazaoSocial"
                v-if="index === 0 || cocorretor.choosed"
                @change="updateCnpjCorretor($event, index)"
                disabled
              />
              <InputSearchSelect
                class="w-7/12"
                v-else                
                label="Digite o CNPJ ou Razão Social para buscar um segurado."
                :required="true"
                icon="onpoint-caret-down"
                v-model="cnpjRazaoSocial"
                :infoData="dadosBuscaCorretor"
                :isLoading="isLoadingSearchCorretor"                
              >
                <template v-slot:infoData>
                <div v-for="(item, i) in dadosBuscaCorretor" :key="i">
                  <div           
                  @click="getCorretor(item, index)"         
                  >
                    <Card class="item-searched" v-if="i < 3">
                      <template #title>
                        <div class="title">
                          {{ item.BrokerFantasyName }}
                        </div>
                      </template>
                      <template #content>
                        <div class="informations">
                          {{ item.BrokerDocument }}
                        </div>
                      </template>
                    </Card>
                  </div>
                </div>
              </template>
              </InputSearchSelect>
              <div class="w-1/5">
                <OnpointInputCurrency
                  required
                  label="Valor da comissão"
                  v-model="cocorretor.AmountValue"
                  @change="valueIsChanged('value', index)"
                />
              </div>
              <OnpointInput
                name="percentRate"
                required
                label="% de rateio"
                class="w-1/5"
                v-model="cocorretor.Rate"
                @change="valueIsChanged('rate', index)"
              />
            </div>
          </div>
          <div class="mb-5 none-trash-button">
            <Button
              v-if="index !== 0"
              class="trash-button"
              @click="removeCocorretor(index)"
              ><i class="onpoint-trash"></i
            ></Button>
          </div>
        </div>
      </div>
    </div>
    <div>
      <div class="card-to-button-center mt-2">
        <Button
          :label="
            cocorretores[0]
              ? 'Adicionar outro corretor'
              : 'Adicionar co-corretagem'
          "
          class="white-button-center p-button-rounded p-button-sm"
          @click="addCocorretor()"
        ></Button>
      </div>
    </div>
  </div>
</template>

<script>
import Button from "primevue/button";
import { mapGetters, mapActions } from "vuex";
import OnpointInput from "../../../../../components/onpoint-input";
import OnpointInputCurrency from "../../../../../components/onpoint-input-currency";
import InputSearchSelect from "../../../../../components/onpoint-input-text-search-select";
import Card from "primevue/card";

export default {
  components: {
    Button,
    OnpointInput,
    OnpointInputCurrency,
    InputSearchSelect,
    Card
  },
  data() {
    return {
      cocorretores: [],
      corretores: [],
      cnpjRazaoSocial:null,
      dadosBuscaCorretor: [],
      isLoadingSearchCorretor: false,
    };
  },
  computed: {
    ...mapGetters("proposta-module", ["proposta", "cocorretagem"]),
    existeCocorretor() {
      return this.cocorretores[0] ? true : false;
    },
  },
  watch:{
    cnpjRazaoSocial(val){
      this.debounceSearchBroker(val)
    }
  },
  methods: {
    ...mapActions("proposta-module", ["adicionaCocorretagem"]),
    ...mapActions("corretor-module", ["searchCorretores"]),
    addCocorretor() {
      if (!this.cocorretores[0]) {
        this.cocorretores.push({
          CoBrokerId: this.proposta.Broker.Id,
          Cnpj: this.proposta.Broker.CpfCnpj,
          RazaoSocial: this.proposta.Broker.Name,
          AmountValue: this.proposta.ComissionValue,
          Rate: "100",
          Id: 0,
          Leader: true,
          CorretorCount: "Corretor Líder",
          ChangedValue: false,
        });
      } else {
        this.cocorretores.push({
          CoBrokerId: null,
          Cnpj: null,
          RazaoSocial: null,
          AmountValue: 0,
          Rate: 0,
          Id: null,
          Leader: false,
          CorretorCount: "Corretagem",
          ChangedValue: false,
        });
      }
    },
    removeCocorretor(index) {
      this.cocorretores.splice(index, 1);
    },
    getCorretor(cocorretor, index) {
      this.cocorretores[index] = {
        ...this.cocorretores[index],
        Cnpj:cocorretor.BrokerDocument,
        CoBrokerId: cocorretor.BrokerId,        
        RazaoSocial:cocorretor.BrokerFantasyName,
        cocorretor,       
        choosed:true 
      }
      this.dadosBuscaCorretor=[],
      this.cnpjRazaoSocial=null
      this.$forceUpdate()
    },
    valueIsChanged(type, id) {
      let newTotal = 0;
      let isNoChanged = 0;
      let total = 100;
      let restPercentTotal = 0;
      let restValueTotal = 0;
      this.cocorretores[id].changedValue = true;
      this.cocorretores.map((corretor) => {
        if (corretor.changedValue) {
          if (type === "rate") {
            newTotal += parseFloat(corretor.Rate);
            const amount = (this.proposta.ComissionValue * corretor.Rate) / 100;
            corretor.AmountValue = parseFloat(amount.toFixed(2));
          }
          if (type === "value") {
            newTotal += parseFloat(corretor.AmountValue);
            const percentBroker =
              (100 * corretor.AmountValue) / this.proposta.ComissionValue;
            corretor.Rate = parseFloat(percentBroker.toFixed(2));
          }
          total = this.proposta.ComissionValue;
        } else {
          isNoChanged++;
        }
      });
      if (isNoChanged === 0) {
        this.addStoreCocorretagem([...this.cocorretores]);
        return;
      }

      if (type === "rate") {
        restPercentTotal = (100 - newTotal) / isNoChanged;
        restValueTotal =
          (this.proposta.ComissionValue * restPercentTotal) / 100;
      } else {
        restValueTotal =
          (this.proposta.ComissionValue - newTotal) / isNoChanged;

        restPercentTotal =
          (100 * restValueTotal) / this.proposta.ComissionValue;
      }

      this.cocorretores.map((corretor) => {
        if (!corretor.changedValue) {
          corretor.Rate = restPercentTotal.toFixed(2);
          corretor.AmountValue = parseFloat(restValueTotal.toFixed(2));
        }
      });
      this.addStoreCocorretagem(this.$utils.deepClone(this.cocorretores));
    },
    addStoreCocorretagem(cocorretagem) {
      if (Object.keys(cocorretagem).length === 0) {
        this.adicionaCocorretagem(newCocorretagens);
        return;
      }
      const newCocorretagens = cocorretagem.filter((corretor, index) => {
        if (this.proposta.ProposalTypeId == 2) {
          if (parseFloat(corretor.Rate) > 100) {
            this.cocorretores[index].Rate = 0;
            this.cocorretores[index].AmountValue = 0;
            this.$onpoint.errorModal(
              "O percentual do Rateio não pode ultrapassar 100%"
            );
          }
        } else {
          if (
            parseFloat(corretor.Rate) > 100 ||
            parseFloat(corretor.AmountValue) < 0
          ) {
            this.cocorretores[index].Rate = 0;
            this.cocorretores[index].AmountValue = 0;
            this.$onpoint.errorModal(
              "O percentual do Rateio não pode ultrapassar 100%"
            );
          }
        }
      });

      if (JSON.stringify(newCocorretagens) === JSON.stringify(cocorretagem)) {
        this.adicionaCocorretagem(this.$utils.deepClone(newCocorretagens));
      }
    },
    debounceSearchBroker: _.debounce(function (searchString) {
      if (searchString && searchString.length > 3) {
        this.isLoadingSearchCorretor = true;
        this.searchCorretores(searchString)
          .then((respCorretores) => {  
            if (respCorretores.length > 0) {
              this.dadosBuscaCorretor = respCorretores.map((corretor) => {
                return {
                  ...corretor,
                  Locations: corretor.Locations.filter(
                    (locations) => locations.IsMain === true
                  ),
                };
              });
            }            
            this.isLoadingSearchCorretor = false;
          })
          .catch((err) => {});
      } else {
        return;
      }
    }, 500),
  },
};
</script>

<style lang="scss" scoped>
.card-component {
  background-color: rgba(249, 250, 251, 1);
  margin: 0px;
  padding: 0px;
  border-radius: 8px;
  width: 100%;
  .title {
    font-family: "Montserrat", Helvetica, Arial, sans-serif;
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
    margin: 0px;
  }
}
.trash-button {
  height: 120px;
  margin-left: 30px;
  width: 40px;
  background: rgba(251, 178, 191, 1);
  border-radius: 8px !important;
  i:before {
    font-size: 24px;
  }
  border-color: #f88c9e;
  display: flex;
  justify-content: center;
  align-items: center;
  color: black;
}

.trash-button:hover {
  background: rgba(251, 178, 191, 0.8);
}

.white-button-center {
  color: black;
  background: white;
  border: solid 1px rgba(209, 213, 219, 1);
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Montserrat", Helvetica, Arial, sans-serif;
  font-weight: 500;
  line-height: 20px;
}
.card-to-button-center {
  background-color: rgba(249, 250, 251, 1);
  box-shadow: none;
  border: none;
  border-radius: 12px;
  padding: 10px;
  display: flex;
  justify-content: center;
}
.none-trash-button {
  width: 70px;
}
.item-searched {
  box-shadow: none;
  margin: 2px;
  .title {
    font-family: "Montserrat", Helvetica, Arial, sans-serif;
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
  }
  .informations {
    font-family: "Montserrat", Helvetica, Arial, sans-serif;
    font-weight: 400;
    font-size: 11px;
    line-height: 16px;
  }
  :deep(.p-card-content) {
    padding: 0px;
  }
}
.item-searched:hover {
  background-color: rgba(243, 244, 246, 1);
}
.item-searched:active {
  background-color: rgba(var(--vs-primary, 1));
  color: white;
}
</style>